import { render, staticRenderFns } from "./redEnvelopeRainNone.vue?vue&type=template&id=961e8700&scoped=true"
import script from "./redEnvelopeRainNone.vue?vue&type=script&lang=js"
export * from "./redEnvelopeRainNone.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/redEnvelopeRainNone.less?vue&type=style&index=0&id=961e8700&prod&scoped=true&lang=less&external"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_cache-loader@4.1.0_webpack@4.47.0__css-load_7907ed7515d572276d45114f834f04a7/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "961e8700",
  null
  
)

export default component.exports